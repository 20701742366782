export default [
  { key: "parking", group: "parking" },
  { key: "entrance1", group: "entrance" },
  { key: "entrance2", group: "entrance" },
  { key: "entrance3", group: "entrance" },
  { key: "movement", group: "movement" },
  { key: "service", group: "service" },
  { key: "toilet", group: "toilet" },
  { key: "navigation", group: "navigation" },
  { key: "serviceAccessibility", group: "serviceAccessibility" }
];
