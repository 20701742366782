//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get, call } from "vuex-pathify";
import open from "oauth-open";

export default {
  name: "LoginSocialButtons",
  computed: {
    viModeEnabled: get("visualImpairedModeSettings/enabled"),
    providers() {
      return {
        facebook: {
          width: 580,
          height: 400,
        },
        google: {
          width: 452,
          height: 633,
        },
        vkontakte: {
          width: 668,
          height: 380,
        },
        mailru: {
          width: 446,
          height: 295,
        },
      };
    },
  },
  methods: {
    openPopup(provider) {
      open(
        `/api/oauth/${provider}/redirect`,
        this.providers[provider],
        (err, code) => {
          if (err) {
            return;
          }
          this.oauthAuthenticate({ code: code.code, provider });
        }
      );
    },
    oauthAuthenticate: call("authentication/oauthAuthenticate"),
  },
};
