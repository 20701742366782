//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { call, get } from "vuex-pathify";
export default {
  name: "SelectCategory",
  props: {
    visible: {
      type: Boolean,
    },
    object: {
      type: Object,
    },
  },
  data() {
    return {
      note: "",
      selectedCat: "",
    };
  },
  computed: {
    ...get("disabilitiesCategorySettings", {
      popupOpen: "popupOpen",
      selectedCategory: "category",
      categories: "categories",
    }),
    disabilityCategoriesList() {
      return this.categories.filter(({ key }) => key !== "justView");
    },
  },
  methods: {
    ...call("disabilitiesCategorySettings", ["selectCategory", "init"]),
    close() {
      this.$emit("close");
    },
    selectCat(cat) {
      this.selectedCat = cat;
    },
    async showNextModal() {
      this.$emit("close");
      await this.$emit("updateUserCategory", this.selectedCat);
    },
  },
};
