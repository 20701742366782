var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buttons"},[_c('span',{staticClass:"title"},[_vm._v("Войти")]),_vm._v(" "),_c('a',{staticClass:"button button_google",on:{"click":function($event){return _vm.openPopup('google')}}},[_c('img',{attrs:{"src":!_vm.viModeEnabled
          ? require('~/assets/img/social/login-google.svg')
          : require('~/assets/img/social/login-google-black.svg')}}),_vm._v(" "),_c('span',[_vm._v("Войти через Google")])]),_vm._v(" "),_c('button',{staticClass:"button",on:{"click":function($event){return _vm.openPopup('vkontakte')}}},[_c('img',{attrs:{"src":!_vm.viModeEnabled
          ? require('~/assets/img/social/login-vk.svg')
          : require('~/assets/img/social/login-vk-black.svg')}})]),_vm._v(" "),_c('button',{staticClass:"button",on:{"click":function($event){return _vm.openPopup('facebook')}}},[_c('img',{attrs:{"src":!_vm.viModeEnabled
          ? require('~/assets/img/social/login-facebook.svg')
          : require('~/assets/img/social/login-facebook-black.svg')}})]),_vm._v(" "),_c('button',{staticClass:"button",on:{"click":function($event){return _vm.openPopup('mailru')}}},[_c('img',{attrs:{"src":!_vm.viModeEnabled
          ? require('~/assets/img/social/login-mailru.svg')
          : require('~/assets/img/social/login-mailru-black.svg')}})])])}
var staticRenderFns = []

export { render, staticRenderFns }