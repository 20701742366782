//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IntroForm from "./../components/IntroForm.vue";
import StartCategoryForm from "./../components/StartCategoryForm.vue";
import ObjectModal from "./../components/ObjectModal.vue";
import MainMap from "./../components/MainMap.vue";
import LoginForm from "../components/LoginForm";
import LangSelect from "./../components/LangSelect";
import CitySelector from "./../components/CitySelector";
import { sync, call, get } from "vuex-pathify";
import Username from "../components/Username";
import IsParticipantModal from "../components/modals/IsParticipantModal";
import SelectCategoryModal from "../components/modals/SelectCategoryModal";
import SubscribeNotifModal from "../components/modals/SubscribeNotifModal";
import SubscribeNotifDoneModal from "../components/modals/SubscribeNotifDoneModal";
import SelectObjectTypeModal from "@/components/modals/SelectObjectTypeModal";
import DetectLocation from "@/components/modals/AutoDetectLocation";
import MobileMenu from "@/components/MobileMenu";
import StatisticsBtn from "~/components/statistics/StatisticsBtn";

export default {
  data() {
    return {
      mobileOpened: false,
      availabilityToggle: false,
      isParticipantModalVisible: false,
      selectCategoryModalVisible: false,
      subscribeNotifModalVisible: false,
      subscribeNotifDoneModalVisible: false,
      objectTypeSelect: false,
      location: [],
      detectModal: false,
    };
  },
  components: {
    Username,
    LoginForm,
    IntroForm,
    StartCategoryForm,
    ObjectModal,
    MainMap,
    LangSelect,
    CitySelector,
    IsParticipantModal,
    SelectCategoryModal,
    SubscribeNotifModal,
    SubscribeNotifDoneModal,
    SelectObjectTypeModal,
    DetectLocation,
    MobileMenu,
    StatisticsBtn,
  },
  computed: {
    selectedCity: get("settings/cityId"),
    currentCategory: get("disabilitiesCategorySettings/currentCategory"),
    popupOpen: sync("disabilitiesCategorySettings/popupOpen"),
    category: sync("disabilitiesCategorySettings/category"),
    user: sync("authentication/user"),
    name: get("authentication/name"),
    modal: sync("authentication/modal"),
    isCitySelected: get("settings/citySelected"),
    ...sync("map", ["accessibilityLevels"]),
    isAuthenticated() {
      return !!this.user;
    },
  },
  created() {
    this.$nuxt.$on("mainPageMobOpened", this.mobileOpenedTrue);
  },
  beforeDestroy() {
    this.$nuxt.$off("mainPageMobOpened");
  },
  mounted() {
    setTimeout(() => {
      this.selectCity(this.selectedCity);
    }, 1000);
    // if (this.$route.query.cat == "null") {
    //   this.selectCategory("hearing");
    //   this.$router.replace({ ...this.$route, query: {} });
    // }
  },
  methods: {
    selectCity: call("settings/select"),
    showDetectModal() {
      setTimeout(() => {
        if (!this.isCitySelected) {
          this.detectModal = true;
        }
      }, 100);
    },
    setLocation(event) {
      this.location = event;
    },
    closeDetectModal() {
      this.detectModal = false;
    },
    mobileOpenedTrue: function(count) {
      this.mobileOpened = !this.mobileOpened;
    },
    mobileOpenedFalse: function(count) {
      this.mobileOpened = false;
    },
    ...call("disabilitiesCategorySettings", ["selectCategory", "init"]),
    enableVisualImpairedMode: call("visualImpairedModeSettings/enable"),
    ...call("map", ["toggleCategory", "toggleAccessibilityLevel"]),
    async updateUserCategory(category) {
      var newUser = {
        ...this.user,
      };
      newUser.category = category;
      var result = await this.$store.dispatch(
        "authentication/updateProfile",
        newUser
      );
      if (category !== "") {
        this.subscribeNotifModalVisible = true;
      }
    },
    openSelectTypeObject() {
      this.objectTypeSelect = !this.objectTypeSelect;
    },
  },
  head() {
    return {
      title: this.$t("meta.title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("meta.description"),
        },
        {
          hid: "keywords",
          name: "keywords",
          content: this.$t("meta.keywords"),
        },
      ],
    };
  },
};
