//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  methods: {
    openModals() {
      this.$store.dispatch("authentication/setModalForFirstTime");
    }
  }
};
