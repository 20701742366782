//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { call, get } from "vuex-pathify";

export default {
  computed: {
    ...get("disabilitiesCategorySettings", {
      popupOpen: "popupOpen",
      selectedCategory: "category",
      categories: "categories",
    }),
    disabilityCategoriesList() {
      return this.categories.filter(({ key }) => key !== "justView");
    },
  },
  methods: {
    ...call("disabilitiesCategorySettings", ["selectCategory", "init"]),
    selectCat(value) {
      this.selectCategory(value);
      this.$emit("showDetectModal");
    },
  },
};
