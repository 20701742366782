var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-form isOpened",class:_vm.viModeEnabled
      ? ((_vm.visualImpairedModeSettings.colorTheme) + " " + (_vm.visualImpairedModeSettings.fontFamily) + " " + (_vm.visualImpairedModeSettings.fontSize))
      : ""},[_c('div',{staticClass:"login-form__content"},[_c('div',{staticClass:"login-form__card",class:{ expanded: _vm.expanded }},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header__img"},[_c('img',{attrs:{"src":!_vm.viModeEnabled
                ? require('~/assets/logo_doskaz.svg')
                : _vm.visualImpairedModeSettings.colorTheme === 'white'
                ? require('~/assets/logo_doskaz-vi-black.svg')
                : require('~/assets/logo_doskaz-vi-white.svg')}})]),_vm._v(" "),_c('div',{staticClass:"header__title"},[_c('span',[_vm._v(_vm._s(_vm.$t("login.popupTitle")))])]),_vm._v(" "),_c('div',{staticClass:"close",on:{"click":_vm.loginFormClose}},[_c('img',{attrs:{"src":!_vm.viModeEnabled
                ? require('~/assets/icons/cross.svg')
                : _vm.visualImpairedModeSettings.colorTheme === 'white'
                ? require('~/assets/icons/cross.svg')
                : require('~/assets/icons/cross-white.svg')}})])]),_vm._v(" "),(!_vm.$route.query.popup)?[_c('login-social-buttons'),_vm._v(" "),_c('div',{staticClass:"phone-form"},[_c('div',{staticClass:"phone-form__switch phone-advice",class:{ active: _vm.showPhoneAuthForm },on:{"click":function($event){_vm.showPhoneAuthForm = true}}},[_c('span',[_vm._v(_vm._s(_vm.$t("login.phoneLoginAdvice")))])]),_vm._v(" "),(_vm.showPhoneAuthForm)?_c('phone-auth-form',{on:{"expanded":function($event){_vm.expanded = true}}}):_vm._e()],1)]:_c('div',{staticClass:"phone-form",staticStyle:{"margin-top":"40px"}},[_c('div',{staticClass:"phone-form__switch"},[_c('span',[_vm._v(_vm._s(_vm.$t("login.profileFillingAdvice")))]),_vm._v(" "),_c('phone-auth-form-points')],1)]),_vm._v(" "),_c('div',{staticClass:"intro-files__container"},[_vm._v("\n        "+_vm._s(_vm.$t("youAccept.main"))+"\n        "),_c('nuxt-link',{attrs:{"to":'/privacy_policy.pdf',"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("youAccept.privacyPolicy")))]),_vm._v("\n        и\n        "),_c('nuxt-link',{attrs:{"to":'/terms-of-service.pdf',"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("youAccept.termsOfUse")))])],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }