//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { call, get } from "vuex-pathify";
import LangSelect from "~/components/LangSelect";
import LoginForm from "../components/LoginForm";
import { mapState } from "vuex";
import MobileMenu from "@/components/MobileMenu";

export default {
  data() {
    return {
      mobileOpened: false,
      currentClassList: "",
      opened: false,
    };
  },
  components: {
    LangSelect,
    LoginForm,
    MobileMenu,
  },
  computed: {
    avatar() {
      return this.user.avatar || require("~/assets/img/user/default.svg");
    },
    ...mapState({
      user: (state) => state.authentication.user,
    }),
    user: get("authentication/user"),
    mobileMenu: get("settings/mobileMenu"),
  },
  methods: {
    viToggle: call("visualImpairedModeSettings/toggle"),
    logout: call("authentication/logout"),
    mainPageMobOpened: call("settings/menuOpen"),
  },
};
