import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d8ea8eb = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _bbcf78aa = () => interopDefault(import('../src/pages/community_guidelines.vue' /* webpackChunkName: "pages/community_guidelines" */))
const _ec869fee = () => interopDefault(import('../src/pages/complaint.vue' /* webpackChunkName: "pages/complaint" */))
const _0576fb36 = () => interopDefault(import('../src/pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _2d4683b0 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0bc2d396 = () => interopDefault(import('../src/pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _1223b272 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _35d15fa9 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _21a730f2 = () => interopDefault(import('../src/pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _7938ecca = () => interopDefault(import('../src/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _17c3a15c = () => interopDefault(import('../src/pages/profile/achievements.vue' /* webpackChunkName: "pages/profile/achievements" */))
const _31cbdfa8 = () => interopDefault(import('../src/pages/profile/comments.vue' /* webpackChunkName: "pages/profile/comments" */))
const _e3a5157c = () => interopDefault(import('../src/pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _4af3fde0 = () => interopDefault(import('../src/pages/profile/notifications.vue' /* webpackChunkName: "pages/profile/notifications" */))
const _9b06ffe8 = () => interopDefault(import('../src/pages/profile/objects.vue' /* webpackChunkName: "pages/profile/objects" */))
const _95a2fcf4 = () => interopDefault(import('../src/pages/profile/tasks.vue' /* webpackChunkName: "pages/profile/tasks" */))
const _142a905f = () => interopDefault(import('../src/pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _7c0a0a79 = () => interopDefault(import('../src/pages/statisticsAccess.vue' /* webpackChunkName: "pages/statisticsAccess" */))
const _67da53bf = () => interopDefault(import('../src/pages/statisticsTotal.vue' /* webpackChunkName: "pages/statisticsTotal" */))
const _10aaa7d7 = () => interopDefault(import('../src/pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _66537227 = () => interopDefault(import('../src/pages/underConstruction.vue' /* webpackChunkName: "pages/underConstruction" */))
const _78549064 = () => interopDefault(import('../src/pages/visually-impaired-blog.vue' /* webpackChunkName: "pages/visually-impaired-blog" */))
const _f62c5596 = () => interopDefault(import('../src/pages/visually-impaired-blog-inside.vue' /* webpackChunkName: "pages/visually-impaired-blog-inside" */))
const _0914dc56 = () => interopDefault(import('../src/pages/visually-impaired-object-add.vue' /* webpackChunkName: "pages/visually-impaired-object-add" */))
const _0004a64f = () => interopDefault(import('../src/pages/oauth/callback.vue' /* webpackChunkName: "pages/oauth/callback" */))
const _38c87584 = () => interopDefault(import('../src/pages/objects/add.vue' /* webpackChunkName: "pages/objects/add" */))
const _52621d55 = () => interopDefault(import('../src/pages/objects/pdf.vue' /* webpackChunkName: "pages/objects/pdf" */))
const _a95fef7c = () => interopDefault(import('../src/pages/blog/_category.vue' /* webpackChunkName: "pages/blog/_category" */))
const _30067904 = () => interopDefault(import('../src/pages/help/_id.vue' /* webpackChunkName: "pages/help/_id" */))
const _50f78bc6 = () => interopDefault(import('../src/pages/objects/_id.vue' /* webpackChunkName: "pages/objects/_id" */))
const _2fc48c8f = () => interopDefault(import('../src/pages/objects/_id/add-photos.vue' /* webpackChunkName: "pages/objects/_id/add-photos" */))
const _01000f8c = () => interopDefault(import('../src/pages/objects/_id/review.vue' /* webpackChunkName: "pages/objects/_id/review" */))
const _32f210db = () => interopDefault(import('../src/pages/objects/_id/verify.vue' /* webpackChunkName: "pages/objects/_id/verify" */))
const _1943c006 = () => interopDefault(import('../src/pages/blog/_cat/_slug.vue' /* webpackChunkName: "pages/blog/_cat/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0d8ea8eb,
    name: "about___ru"
  }, {
    path: "/community_guidelines",
    component: _bbcf78aa,
    name: "community_guidelines___ru"
  }, {
    path: "/complaint",
    component: _ec869fee,
    name: "complaint___ru"
  }, {
    path: "/contacts",
    component: _0576fb36,
    name: "contacts___ru"
  }, {
    path: "/en",
    component: _2d4683b0,
    name: "index___en"
  }, {
    path: "/help",
    component: _0bc2d396,
    name: "help___ru"
  }, {
    path: "/kz",
    component: _2d4683b0,
    name: "index___kz"
  }, {
    path: "/login",
    component: _1223b272,
    name: "login___ru"
  }, {
    path: "/privacy-policy",
    component: _35d15fa9,
    name: "privacy-policy___ru"
  }, {
    path: "/profile",
    component: _21a730f2,
    children: [{
      path: "",
      component: _7938ecca,
      name: "profile___ru"
    }, {
      path: "achievements",
      component: _17c3a15c,
      name: "profile-achievements___ru"
    }, {
      path: "comments",
      component: _31cbdfa8,
      name: "profile-comments___ru"
    }, {
      path: "edit",
      component: _e3a5157c,
      name: "profile-edit___ru"
    }, {
      path: "notifications",
      component: _4af3fde0,
      name: "profile-notifications___ru"
    }, {
      path: "objects",
      component: _9b06ffe8,
      name: "profile-objects___ru"
    }, {
      path: "tasks",
      component: _95a2fcf4,
      name: "profile-tasks___ru"
    }, {
      path: "tickets",
      component: _142a905f,
      name: "profile-tickets___ru"
    }]
  }, {
    path: "/statisticsAccess",
    component: _7c0a0a79,
    name: "statisticsAccess___ru"
  }, {
    path: "/statisticsTotal",
    component: _67da53bf,
    name: "statisticsTotal___ru"
  }, {
    path: "/terms-of-service",
    component: _10aaa7d7,
    name: "terms-of-service___ru"
  }, {
    path: "/underConstruction",
    component: _66537227,
    name: "underConstruction___ru"
  }, {
    path: "/visually-impaired-blog",
    component: _78549064,
    name: "visually-impaired-blog___ru"
  }, {
    path: "/visually-impaired-blog-inside",
    component: _f62c5596,
    name: "visually-impaired-blog-inside___ru"
  }, {
    path: "/visually-impaired-object-add",
    component: _0914dc56,
    name: "visually-impaired-object-add___ru"
  }, {
    path: "/en/about",
    component: _0d8ea8eb,
    name: "about___en"
  }, {
    path: "/en/community_guidelines",
    component: _bbcf78aa,
    name: "community_guidelines___en"
  }, {
    path: "/en/complaint",
    component: _ec869fee,
    name: "complaint___en"
  }, {
    path: "/en/contacts",
    component: _0576fb36,
    name: "contacts___en"
  }, {
    path: "/en/help",
    component: _0bc2d396,
    name: "help___en"
  }, {
    path: "/en/login",
    component: _1223b272,
    name: "login___en"
  }, {
    path: "/en/privacy-policy",
    component: _35d15fa9,
    name: "privacy-policy___en"
  }, {
    path: "/en/profile",
    component: _21a730f2,
    children: [{
      path: "",
      component: _7938ecca,
      name: "profile___en"
    }, {
      path: "achievements",
      component: _17c3a15c,
      name: "profile-achievements___en"
    }, {
      path: "comments",
      component: _31cbdfa8,
      name: "profile-comments___en"
    }, {
      path: "edit",
      component: _e3a5157c,
      name: "profile-edit___en"
    }, {
      path: "notifications",
      component: _4af3fde0,
      name: "profile-notifications___en"
    }, {
      path: "objects",
      component: _9b06ffe8,
      name: "profile-objects___en"
    }, {
      path: "tasks",
      component: _95a2fcf4,
      name: "profile-tasks___en"
    }, {
      path: "tickets",
      component: _142a905f,
      name: "profile-tickets___en"
    }]
  }, {
    path: "/en/statisticsAccess",
    component: _7c0a0a79,
    name: "statisticsAccess___en"
  }, {
    path: "/en/statisticsTotal",
    component: _67da53bf,
    name: "statisticsTotal___en"
  }, {
    path: "/en/terms-of-service",
    component: _10aaa7d7,
    name: "terms-of-service___en"
  }, {
    path: "/en/underConstruction",
    component: _66537227,
    name: "underConstruction___en"
  }, {
    path: "/en/visually-impaired-blog",
    component: _78549064,
    name: "visually-impaired-blog___en"
  }, {
    path: "/en/visually-impaired-blog-inside",
    component: _f62c5596,
    name: "visually-impaired-blog-inside___en"
  }, {
    path: "/en/visually-impaired-object-add",
    component: _0914dc56,
    name: "visually-impaired-object-add___en"
  }, {
    path: "/kz/about",
    component: _0d8ea8eb,
    name: "about___kz"
  }, {
    path: "/kz/community_guidelines",
    component: _bbcf78aa,
    name: "community_guidelines___kz"
  }, {
    path: "/kz/complaint",
    component: _ec869fee,
    name: "complaint___kz"
  }, {
    path: "/kz/contacts",
    component: _0576fb36,
    name: "contacts___kz"
  }, {
    path: "/kz/help",
    component: _0bc2d396,
    name: "help___kz"
  }, {
    path: "/kz/login",
    component: _1223b272,
    name: "login___kz"
  }, {
    path: "/kz/privacy-policy",
    component: _35d15fa9,
    name: "privacy-policy___kz"
  }, {
    path: "/kz/profile",
    component: _21a730f2,
    children: [{
      path: "",
      component: _7938ecca,
      name: "profile___kz"
    }, {
      path: "achievements",
      component: _17c3a15c,
      name: "profile-achievements___kz"
    }, {
      path: "comments",
      component: _31cbdfa8,
      name: "profile-comments___kz"
    }, {
      path: "edit",
      component: _e3a5157c,
      name: "profile-edit___kz"
    }, {
      path: "notifications",
      component: _4af3fde0,
      name: "profile-notifications___kz"
    }, {
      path: "objects",
      component: _9b06ffe8,
      name: "profile-objects___kz"
    }, {
      path: "tasks",
      component: _95a2fcf4,
      name: "profile-tasks___kz"
    }, {
      path: "tickets",
      component: _142a905f,
      name: "profile-tickets___kz"
    }]
  }, {
    path: "/kz/statisticsAccess",
    component: _7c0a0a79,
    name: "statisticsAccess___kz"
  }, {
    path: "/kz/statisticsTotal",
    component: _67da53bf,
    name: "statisticsTotal___kz"
  }, {
    path: "/kz/terms-of-service",
    component: _10aaa7d7,
    name: "terms-of-service___kz"
  }, {
    path: "/kz/underConstruction",
    component: _66537227,
    name: "underConstruction___kz"
  }, {
    path: "/kz/visually-impaired-blog",
    component: _78549064,
    name: "visually-impaired-blog___kz"
  }, {
    path: "/kz/visually-impaired-blog-inside",
    component: _f62c5596,
    name: "visually-impaired-blog-inside___kz"
  }, {
    path: "/kz/visually-impaired-object-add",
    component: _0914dc56,
    name: "visually-impaired-object-add___kz"
  }, {
    path: "/oauth/callback",
    component: _0004a64f,
    name: "oauth-callback___ru"
  }, {
    path: "/objects/add",
    component: _38c87584,
    name: "objects-add___ru"
  }, {
    path: "/objects/pdf",
    component: _52621d55,
    name: "objects-pdf___ru"
  }, {
    path: "/en/oauth/callback",
    component: _0004a64f,
    name: "oauth-callback___en"
  }, {
    path: "/en/objects/add",
    component: _38c87584,
    name: "objects-add___en"
  }, {
    path: "/en/objects/pdf",
    component: _52621d55,
    name: "objects-pdf___en"
  }, {
    path: "/kz/oauth/callback",
    component: _0004a64f,
    name: "oauth-callback___kz"
  }, {
    path: "/kz/objects/add",
    component: _38c87584,
    name: "objects-add___kz"
  }, {
    path: "/kz/objects/pdf",
    component: _52621d55,
    name: "objects-pdf___kz"
  }, {
    path: "/en/blog/:category?",
    component: _a95fef7c,
    name: "blog-category___en"
  }, {
    path: "/en/help/:id",
    component: _30067904,
    name: "help-id___en"
  }, {
    path: "/en/objects/:id?",
    component: _50f78bc6,
    name: "objects-id___en",
    children: [{
      path: "add-photos",
      component: _2fc48c8f,
      name: "objects-id-add-photos___en"
    }, {
      path: "review",
      component: _01000f8c,
      name: "objects-id-review___en"
    }, {
      path: "verify",
      component: _32f210db,
      name: "objects-id-verify___en"
    }]
  }, {
    path: "/kz/blog/:category?",
    component: _a95fef7c,
    name: "blog-category___kz"
  }, {
    path: "/kz/help/:id",
    component: _30067904,
    name: "help-id___kz"
  }, {
    path: "/kz/objects/:id?",
    component: _50f78bc6,
    name: "objects-id___kz",
    children: [{
      path: "add-photos",
      component: _2fc48c8f,
      name: "objects-id-add-photos___kz"
    }, {
      path: "review",
      component: _01000f8c,
      name: "objects-id-review___kz"
    }, {
      path: "verify",
      component: _32f210db,
      name: "objects-id-verify___kz"
    }]
  }, {
    path: "/en/blog/:cat?/:slug?",
    component: _1943c006,
    name: "blog-cat-slug___en"
  }, {
    path: "/kz/blog/:cat?/:slug?",
    component: _1943c006,
    name: "blog-cat-slug___kz"
  }, {
    path: "/blog/:category?",
    component: _a95fef7c,
    name: "blog-category___ru"
  }, {
    path: "/help/:id",
    component: _30067904,
    name: "help-id___ru"
  }, {
    path: "/objects/:id?",
    component: _50f78bc6,
    name: "objects-id___ru",
    children: [{
      path: "add-photos",
      component: _2fc48c8f,
      name: "objects-id-add-photos___ru"
    }, {
      path: "review",
      component: _01000f8c,
      name: "objects-id-review___ru"
    }, {
      path: "verify",
      component: _32f210db,
      name: "objects-id-verify___ru"
    }]
  }, {
    path: "/blog/:cat?/:slug?",
    component: _1943c006,
    name: "blog-cat-slug___ru"
  }, {
    path: "/",
    component: _2d4683b0,
    name: "index___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
