//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from "vuex-pathify";
import MainHeader from "@/components/MainHeader";
import ViTop from "@/components/ViTop";
import MainFooter from "@/components/MainFooter";

export default {
  components: { MainHeader, ViTop, MainFooter },
  head() {
    return {
      title: this.$t("meta.title"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("meta.description"),
        },
        {
          hid: "keywords",
          name: "keywords",
          content: this.$t("meta.keywords"),
        },
      ],
    };
  },
  computed: {
    viModeEnabled: get("visualImpairedModeSettings/enabled"),
  },
};
