//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from "vue-click-outside";
import { call, get, sync } from "vuex-pathify";

export default {
  name: "CitySelector",
  data() {
    return {
      opened: false,
    };
  },
  directives: {
    ClickOutside,
  },
  computed: {
    openedCityList: sync("cities/openedList"),
    selectedCityTitle() {
      const city = this.cities.find((city) => city.id === this.selectedCity);
      if (city) {
        return city.name;
      }
    },
    cities: get("cities/list"),
    selectedCity: get("settings/cityId"),
  },
  methods: {
    select(id) {
      this.selectCity(id);
      this.close();
    },
    selectCity: call("settings/select"),
    close() {
      this.opened = false;
      this.openedCityList = false;
    },
    open() {
      this.opened = !this.opened;
      this.$emit("open-list", this.cities);
    },
  },
};
