//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from "vuex-pathify";

export default {
  name: "SubscribeNotifDoneModal",
  props: {
    visible: {
      type: Boolean
    },
    object: {
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {
    profile: get('authentication/user'),
    cities: get("cities/list"),
    selectedCity() {
      return this.profile.city_id ? this.cities.find(c => c.id === this.profile.city_id).name : null
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    showNextModal() {
      this.$emit("close");
      this.$emit("showNextModal");
    }
  }
};
