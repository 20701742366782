//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from "vuex-pathify";

export default {
  name: "SubscribeNotifModal",
  props: {
    visible: {
      type: Boolean
    },
    object: {
      type: Object
    },
    edit: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  computed: {
    profile: get('authentication/user')
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async subscribe() {
      await this.$store.dispatch("notifications/subscribe", this.profile.category);
      this.$emit("close");
      this.$emit("showNextModal");
    }
  }
};
