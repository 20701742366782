import { render, staticRenderFns } from "./main.vue?vue&type=template&id=4153e4c4&"
import script from "./main.vue?vue&type=script&lang=js&"
export * from "./main.vue?vue&type=script&lang=js&"
import style0 from "./main.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IntroForm: require('/frontend/src/components/IntroForm.vue').default,MainMap: require('/frontend/src/components/MainMap.vue').default,LangSelect: require('/frontend/src/components/LangSelect.vue').default,StartCategoryForm: require('/frontend/src/components/StartCategoryForm.vue').default,MobileMenu: require('/frontend/src/components/MobileMenu.vue').default,StatisticsBtn: require('/frontend/src/components/statistics/StatisticsBtn.vue').default})
