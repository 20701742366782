//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sync, get, call } from "vuex-pathify";
import ClickOutside from "vue-click-outside";

export default {
  name: "DetectLocation",
  computed: {
    city: get("detectLocation/cityName"),
    location: get("detectLocation/position"),
    loaded: get("detectLocation/loaded"),
    openedCityList: sync("cities/openedList"),
  },
  directives: {
    ClickOutside,
  },

  methods: {
    mainPageMobOpened: call("settings/menuOpen"),
    close() {
      this.$emit("close");
      this.setCitySelected();
    },
    openCityList() {
      this.$emit("close");
      this.setCitySelected();
      this.openedCityList = !this.openedCityList;
    },
    setCitySelected: call("settings/setCitySeleted"),
  },
};
