//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get, call } from "vuex-pathify";

export default {
  name: "ViTop",
  head() {
    return {
      bodyAttrs: {
        class: this.bodyClasses,
      },
    };
  },
  methods: {
    changeLang(target) {
      this.$router.push(this.switchLocalePath(target));
    },
    disableVisualImpairedMode: call("visualImpairedModeSettings/disable"),
    ...call("visualImpairedModeSettings", [
      "changeColorTheme",
      "changeFontSize",
      "changeFontFamily",
    ]),
  },
  computed: {
    visualImpairedModeSettings: get("visualImpairedModeSettings"),
    user: get("authentication/user"),
    name: get("authentication/name"),
    ...get("visualImpairedModeSettings", [
      "bodyClasses",
      "enabled",
      "fontFamily",
      "fontSize",
      "colorTheme",
    ]),
  },
};
