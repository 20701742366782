//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDate } from "date-fns";
import { call, get } from "vuex-pathify";
export default {
  data() {
    return {};
  },
  computed: {
    viModeEnabled: get("visualImpairedModeSettings/enabled"),
    visualImpairedModeSettings: get("visualImpairedModeSettings"),
    getPrivacy() {
      var date = new Date();
      return `Doskaz.kz (c) ${date.getFullYear()} Все права защищены`;
    },
  },
};
