import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Authenticated: () => import('../../src/components/Authenticated.vue' /* webpackChunkName: "components/authenticated" */).then(c => wrapFunctional(c.default || c)),
  BackBtn: () => import('../../src/components/BackBtn.vue' /* webpackChunkName: "components/back-btn" */).then(c => wrapFunctional(c.default || c)),
  CategorySelector: () => import('../../src/components/CategorySelector.vue' /* webpackChunkName: "components/category-selector" */).then(c => wrapFunctional(c.default || c)),
  CityList: () => import('../../src/components/CityList.vue' /* webpackChunkName: "components/city-list" */).then(c => wrapFunctional(c.default || c)),
  CitySelector: () => import('../../src/components/CitySelector.vue' /* webpackChunkName: "components/city-selector" */).then(c => wrapFunctional(c.default || c)),
  Comments: () => import('../../src/components/Comments.vue' /* webpackChunkName: "components/comments" */).then(c => wrapFunctional(c.default || c)),
  ComplaintVideoLink: () => import('../../src/components/ComplaintVideoLink.vue' /* webpackChunkName: "components/complaint-video-link" */).then(c => wrapFunctional(c.default || c)),
  Dropdown: () => import('../../src/components/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c)),
  DropdownBlock: () => import('../../src/components/DropdownBlock.vue' /* webpackChunkName: "components/dropdown-block" */).then(c => wrapFunctional(c.default || c)),
  FormattedDate: () => import('../../src/components/FormattedDate.vue' /* webpackChunkName: "components/formatted-date" */).then(c => wrapFunctional(c.default || c)),
  IntroForm: () => import('../../src/components/IntroForm.vue' /* webpackChunkName: "components/intro-form" */).then(c => wrapFunctional(c.default || c)),
  LangSelect: () => import('../../src/components/LangSelect.vue' /* webpackChunkName: "components/lang-select" */).then(c => wrapFunctional(c.default || c)),
  LoginForm: () => import('../../src/components/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c)),
  LoginSocialButtons: () => import('../../src/components/LoginSocialButtons.vue' /* webpackChunkName: "components/login-social-buttons" */).then(c => wrapFunctional(c.default || c)),
  MainFilter: () => import('../../src/components/MainFilter.vue' /* webpackChunkName: "components/main-filter" */).then(c => wrapFunctional(c.default || c)),
  MainFooter: () => import('../../src/components/MainFooter.vue' /* webpackChunkName: "components/main-footer" */).then(c => wrapFunctional(c.default || c)),
  MainHeader: () => import('../../src/components/MainHeader.vue' /* webpackChunkName: "components/main-header" */).then(c => wrapFunctional(c.default || c)),
  MainMap: () => import('../../src/components/MainMap.vue' /* webpackChunkName: "components/main-map" */).then(c => wrapFunctional(c.default || c)),
  MobileMenu: () => import('../../src/components/MobileMenu.vue' /* webpackChunkName: "components/mobile-menu" */).then(c => wrapFunctional(c.default || c)),
  NormalIndexPage: () => import('../../src/components/NormalIndexPage.vue' /* webpackChunkName: "components/normal-index-page" */).then(c => wrapFunctional(c.default || c)),
  ObjectModal: () => import('../../src/components/ObjectModal.vue' /* webpackChunkName: "components/object-modal" */).then(c => wrapFunctional(c.default || c)),
  Pagination: () => import('../../src/components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c)),
  PhoneAuthForm: () => import('../../src/components/PhoneAuthForm.vue' /* webpackChunkName: "components/phone-auth-form" */).then(c => wrapFunctional(c.default || c)),
  PhoneAuthFormPoints: () => import('../../src/components/PhoneAuthFormPoints.vue' /* webpackChunkName: "components/phone-auth-form-points" */).then(c => wrapFunctional(c.default || c)),
  Sidebar: () => import('../../src/components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c)),
  StartCategory: () => import('../../src/components/StartCategory.vue' /* webpackChunkName: "components/start-category" */).then(c => wrapFunctional(c.default || c)),
  StartCategoryForm: () => import('../../src/components/StartCategoryForm.vue' /* webpackChunkName: "components/start-category-form" */).then(c => wrapFunctional(c.default || c)),
  Timeline: () => import('../../src/components/Timeline.vue' /* webpackChunkName: "components/timeline" */).then(c => wrapFunctional(c.default || c)),
  UserAvatar: () => import('../../src/components/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c)),
  UserTabs: () => import('../../src/components/UserTabs.vue' /* webpackChunkName: "components/user-tabs" */).then(c => wrapFunctional(c.default || c)),
  Username: () => import('../../src/components/Username.vue' /* webpackChunkName: "components/username" */).then(c => wrapFunctional(c.default || c)),
  ViFooter: () => import('../../src/components/ViFooter.vue' /* webpackChunkName: "components/vi-footer" */).then(c => wrapFunctional(c.default || c)),
  ViHeader: () => import('../../src/components/ViHeader.vue' /* webpackChunkName: "components/vi-header" */).then(c => wrapFunctional(c.default || c)),
  ViIndexPage: () => import('../../src/components/ViIndexPage.vue' /* webpackChunkName: "components/vi-index-page" */).then(c => wrapFunctional(c.default || c)),
  ViTop: () => import('../../src/components/ViTop.vue' /* webpackChunkName: "components/vi-top" */).then(c => wrapFunctional(c.default || c)),
  BlogInside: () => import('../../src/components/blog/BlogInside.vue' /* webpackChunkName: "components/blog-inside" */).then(c => wrapFunctional(c.default || c)),
  BlogMain: () => import('../../src/components/blog/BlogMain.vue' /* webpackChunkName: "components/blog-main" */).then(c => wrapFunctional(c.default || c)),
  BlogCommentsBlock: () => import('../../src/components/blog/CommentsBlock.vue' /* webpackChunkName: "components/blog-comments-block" */).then(c => wrapFunctional(c.default || c)),
  ModalsAutoDetectLocation: () => import('../../src/components/modals/AutoDetectLocation.vue' /* webpackChunkName: "components/modals-auto-detect-location" */).then(c => wrapFunctional(c.default || c)),
  ModalsAvailabilityFilter: () => import('../../src/components/modals/AvailabilityFilter.vue' /* webpackChunkName: "components/modals-availability-filter" */).then(c => wrapFunctional(c.default || c)),
  ModalsIsParticipantModal: () => import('../../src/components/modals/IsParticipantModal.vue' /* webpackChunkName: "components/modals-is-participant-modal" */).then(c => wrapFunctional(c.default || c)),
  ModalsSelectCategoryModal: () => import('../../src/components/modals/SelectCategoryModal.vue' /* webpackChunkName: "components/modals-select-category-modal" */).then(c => wrapFunctional(c.default || c)),
  ModalsSelectObjectTypeModal: () => import('../../src/components/modals/SelectObjectTypeModal.vue' /* webpackChunkName: "components/modals-select-object-type-modal" */).then(c => wrapFunctional(c.default || c)),
  ModalsSubscribeNotifDoneModal: () => import('../../src/components/modals/SubscribeNotifDoneModal.vue' /* webpackChunkName: "components/modals-subscribe-notif-done-modal" */).then(c => wrapFunctional(c.default || c)),
  ModalsSubscribeNotifModal: () => import('../../src/components/modals/SubscribeNotifModal.vue' /* webpackChunkName: "components/modals-subscribe-notif-modal" */).then(c => wrapFunctional(c.default || c)),
  ComplaintContent: () => import('../../src/components/complaint/ComplaintContent.vue' /* webpackChunkName: "components/complaint-content" */).then(c => wrapFunctional(c.default || c)),
  ComplaintPostSubmitMessage: () => import('../../src/components/complaint/PostSubmitMessage.vue' /* webpackChunkName: "components/complaint-post-submit-message" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddAccessibilityScore: () => import('../../src/components/object_add/AccessibilityScore.vue' /* webpackChunkName: "components/object-add-accessibility-score" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddAttributeField: () => import('../../src/components/object_add/AttributeField.vue' /* webpackChunkName: "components/object-add-attribute-field" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddAttributesList: () => import('../../src/components/object_add/AttributesList.vue' /* webpackChunkName: "components/object-add-attributes-list" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddField: () => import('../../src/components/object_add/Field.vue' /* webpackChunkName: "components/object-add-field" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddFirstStep: () => import('../../src/components/object_add/FirstStep.vue' /* webpackChunkName: "components/object-add-first-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddContent: () => import('../../src/components/object_add/ObjectAddContent.vue' /* webpackChunkName: "components/object-add-content" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddPhotoUploader: () => import('../../src/components/object_add/PhotoUploader.vue' /* webpackChunkName: "components/object-add-photo-uploader" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddPostAdditionMessage: () => import('../../src/components/object_add/PostAdditionMessage.vue' /* webpackChunkName: "components/object-add-post-addition-message" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddPresenceMessage: () => import('../../src/components/object_add/PresenceMessage.vue' /* webpackChunkName: "components/object-add-presence-message" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZoneStep: () => import('../../src/components/object_add/ZoneStep.vue' /* webpackChunkName: "components/object-add-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectsNormalObjectView: () => import('../../src/components/objects/NormalObjectView.vue' /* webpackChunkName: "components/objects-normal-object-view" */).then(c => wrapFunctional(c.default || c)),
  ObjectsViObjectView: () => import('../../src/components/objects/ViObjectView.vue' /* webpackChunkName: "components/objects-vi-object-view" */).then(c => wrapFunctional(c.default || c)),
  StatisticsAnychartColumn: () => import('../../src/components/statistics/AnychartColumn.vue' /* webpackChunkName: "components/statistics-anychart-column" */).then(c => wrapFunctional(c.default || c)),
  StatisticsAnychartDoughnut: () => import('../../src/components/statistics/AnychartDoughnut.vue' /* webpackChunkName: "components/statistics-anychart-doughnut" */).then(c => wrapFunctional(c.default || c)),
  StatisticsBtn: () => import('../../src/components/statistics/StatisticsBtn.vue' /* webpackChunkName: "components/statistics-btn" */).then(c => wrapFunctional(c.default || c)),
  UserProfileEdit: () => import('../../src/components/user/ProfileEdit.vue' /* webpackChunkName: "components/user-profile-edit" */).then(c => wrapFunctional(c.default || c)),
  UserAchievment: () => import('../../src/components/user/UserAchievment.vue' /* webpackChunkName: "components/user-achievment" */).then(c => wrapFunctional(c.default || c)),
  UserAchievments: () => import('../../src/components/user/UserAchievments.vue' /* webpackChunkName: "components/user-achievments" */).then(c => wrapFunctional(c.default || c)),
  UserComment: () => import('../../src/components/user/UserComment.vue' /* webpackChunkName: "components/user-comment" */).then(c => wrapFunctional(c.default || c)),
  UserComments: () => import('../../src/components/user/UserComments.vue' /* webpackChunkName: "components/user-comments" */).then(c => wrapFunctional(c.default || c)),
  UserLevel: () => import('../../src/components/user/UserLevel.vue' /* webpackChunkName: "components/user-level" */).then(c => wrapFunctional(c.default || c)),
  UserNotification: () => import('../../src/components/user/UserNotification.vue' /* webpackChunkName: "components/user-notification" */).then(c => wrapFunctional(c.default || c)),
  UserNotifications: () => import('../../src/components/user/UserNotifications.vue' /* webpackChunkName: "components/user-notifications" */).then(c => wrapFunctional(c.default || c)),
  UserObject: () => import('../../src/components/user/UserObject.vue' /* webpackChunkName: "components/user-object" */).then(c => wrapFunctional(c.default || c)),
  UserObjects: () => import('../../src/components/user/UserObjects.vue' /* webpackChunkName: "components/user-objects" */).then(c => wrapFunctional(c.default || c)),
  UserPageHeader: () => import('../../src/components/user/UserPageHeader.vue' /* webpackChunkName: "components/user-page-header" */).then(c => wrapFunctional(c.default || c)),
  UserProfile: () => import('../../src/components/user/UserProfile.vue' /* webpackChunkName: "components/user-profile" */).then(c => wrapFunctional(c.default || c)),
  UserTask: () => import('../../src/components/user/UserTask.vue' /* webpackChunkName: "components/user-task" */).then(c => wrapFunctional(c.default || c)),
  UserTasks: () => import('../../src/components/user/UserTasks.vue' /* webpackChunkName: "components/user-tasks" */).then(c => wrapFunctional(c.default || c)),
  UserTasksItem: () => import('../../src/components/user/UserTasksItem.vue' /* webpackChunkName: "components/user-tasks-item" */).then(c => wrapFunctional(c.default || c)),
  UserTicket: () => import('../../src/components/user/UserTicket.vue' /* webpackChunkName: "components/user-ticket" */).then(c => wrapFunctional(c.default || c)),
  UserTickets: () => import('../../src/components/user/UserTickets.vue' /* webpackChunkName: "components/user-tickets" */).then(c => wrapFunctional(c.default || c)),
  UserEvent: () => import('../../src/components/userEvents/UserEvent.vue' /* webpackChunkName: "components/user-event" */).then(c => wrapFunctional(c.default || c)),
  ObjectsVerificationPopupDialog: () => import('../../src/components/objects/verification/PopupDialog.vue' /* webpackChunkName: "components/objects-verification-popup-dialog" */).then(c => wrapFunctional(c.default || c)),
  ObjectsVerificationPostRejectionDialog: () => import('../../src/components/objects/verification/PostRejectionDialog.vue' /* webpackChunkName: "components/objects-verification-post-rejection-dialog" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesMiddleEntranceZoneStep: () => import('../../src/components/object_add/zones/middle/EntranceZoneStep.vue' /* webpackChunkName: "components/object-add-zones-middle-entrance-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesMiddleNavigationZoneStep: () => import('../../src/components/object_add/zones/middle/NavigationZoneStep.vue' /* webpackChunkName: "components/object-add-zones-middle-navigation-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesMiddleParkingZoneStep: () => import('../../src/components/object_add/zones/middle/ParkingZoneStep.vue' /* webpackChunkName: "components/object-add-zones-middle-parking-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesMiddlePathsMovementZoneStep: () => import('../../src/components/object_add/zones/middle/PathsMovementZoneStep.vue' /* webpackChunkName: "components/object-add-zones-middle-paths-movement-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesMiddleServiceAccessibilityStep: () => import('../../src/components/object_add/zones/middle/ServiceAccessibilityStep.vue' /* webpackChunkName: "components/object-add-zones-middle-service-accessibility-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesMiddleServiceAreaZoneStep: () => import('../../src/components/object_add/zones/middle/ServiceAreaZoneStep.vue' /* webpackChunkName: "components/object-add-zones-middle-service-area-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesMiddleToiletZoneStep: () => import('../../src/components/object_add/zones/middle/ToiletZoneStep.vue' /* webpackChunkName: "components/object-add-zones-middle-toilet-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesMiddle: () => import('../../src/components/object_add/zones/middle/index.js' /* webpackChunkName: "components/object-add-zones-middle" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesFullEntranceZoneStep: () => import('../../src/components/object_add/zones/full/EntranceZoneStep.vue' /* webpackChunkName: "components/object-add-zones-full-entrance-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesFullNavigationZoneStep: () => import('../../src/components/object_add/zones/full/NavigationZoneStep.vue' /* webpackChunkName: "components/object-add-zones-full-navigation-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesFullParkingZoneStep: () => import('../../src/components/object_add/zones/full/ParkingZoneStep.vue' /* webpackChunkName: "components/object-add-zones-full-parking-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesFullPathsMovementZoneStep: () => import('../../src/components/object_add/zones/full/PathsMovementZoneStep.vue' /* webpackChunkName: "components/object-add-zones-full-paths-movement-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesFullServiceAccessibilityStep: () => import('../../src/components/object_add/zones/full/ServiceAccessibilityStep.vue' /* webpackChunkName: "components/object-add-zones-full-service-accessibility-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesFullServiceAreaZoneStep: () => import('../../src/components/object_add/zones/full/ServiceAreaZoneStep.vue' /* webpackChunkName: "components/object-add-zones-full-service-area-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesFullToiletZoneStep: () => import('../../src/components/object_add/zones/full/ToiletZoneStep.vue' /* webpackChunkName: "components/object-add-zones-full-toilet-zone-step" */).then(c => wrapFunctional(c.default || c)),
  ObjectAddZonesFull: () => import('../../src/components/object_add/zones/full/index.js' /* webpackChunkName: "components/object-add-zones-full" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
