//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "StatisticsBtn",
  props: {
    title: {
      type: String,
    },
    page: {
      type: String,
    },
    css: {
      type: String,
    },
    classStyle: {
      type: String,
    },
  },
  methods: {},
};
