//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClickOutside from "vue-click-outside";

export default {
  data() {
    return {
      selectLang: false,
    };
  },
  methods: {
    close() {
      this.selectLang = false;
    },
  },
  computed: {
    currentLocale() {
      return this.$i18n.locales.find(
        (locale) => this.$i18n.locale === locale.code
      );
    },
  },
  directives: {
    ClickOutside,
  },
};
