// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/page-error-bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-page{background-size:100%;background-position:bottom;min-height:100vh;background-repeat:no-repeat}.error-page-wrapper{position:relative;background:linear-gradient(180deg,#85cbf3,#fff);height:calc(100vh - 77px)}@media (max-width:768px){.error-page-wrapper{height:calc(100vh - 62px)}}.error-page .vis{background:none}.error-page-bg{position:absolute;top:0;bottom:0;right:0;left:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover}.error-page__content{padding:170px 0 30px}@media (max-width:575px){.error-page__content{padding:60px 0}}.error-page__title{font-weight:600;font-size:48px;line-height:50px;margin:0 0 30px;color:#3a3a3a}@media (max-width:768px){.error-page__title{text-align:center}}@media (max-width:575px){.error-page__title{font-size:30px}}.error-page__text{font-size:18px;max-width:700px;line-height:30px;margin:20px 0;font-family:SFProDisplay;font-weight:500}@media (max-width:1024px){.error-page__text{max-width:500px}}@media (max-width:768px){.error-page__text{margin:auto}}@media (max-width:575px){.error-page__text{font-size:16px;line-height:20px}}.error-page__search{max-width:700px;display:flex;margin-top:45px}.error-page__search .input{height:60px}@media (max-width:768px){.error-page__search .input input{font-size:16px}}@media (max-width:768px){.error-page__search{max-width:500px;margin:45px auto;margin:30px auto}}.error-page__search-button{cursor:pointer;padding:13px 26px;border:1px solid #2d9cdb;border-radius:10px;font-size:18px;font-weight:700;font-family:\"SFProDisplay\";color:#fff;background:#2d9cdb;width:170px;margin-left:15px}@media (max-width:768px){.error-page__search-button{display:none}}.error-page__link{font-size:18px;line-height:20px;display:inline-block;border-bottom:1px solid;transition:opacity .4s}.error-page__link:hover{opacity:.7}.error-page__link-b{margin:44px 0 0}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
