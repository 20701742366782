//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "IsParticipantModal",
  props: {
    visible: {
      type: Boolean,
    },
    object: {
      type: Object,
    },
  },
  data() {
    return {
      note: "",
    };
  },
  methods: {
    updateUserCategory(category) {
      this.$emit("close");
      this.$emit("updateUserCategory", "");
    },
    showCategories() {
      this.$emit("close");
      this.$emit("showCategories");
    },
  },
};
