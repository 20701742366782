//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeader from "~/components/MainHeader";
import { get } from "vuex-pathify";
export default {
  props: ["error"],
  components: {
    MainHeader,
  },
  computed: {
    visualImpairedModeSettings: get("visualImpairedModeSettings"),
  },
};
