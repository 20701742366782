//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from "firebase/app";
import "firebase/auth";
import get from "lodash/get";
import VueMask from "v-mask";
import Vue from "vue";
Vue.use(VueMask);

const errorMessages = {
  "auth/invalid-phone-number": "login.invalidPhoneNumberMessage",
  "auth/invalid-verification-code": "login.invalidSmsCodeMessage"
};

export default {
  name: "PhoneAuthForm",
  data() {
    return {
      phoneAuth: {
        number: "+7",
        code: null
      },
      errors: {
        number: null,
        code: null
      },
      confirmationResult: null
    };
  },
  mounted() {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: process.env.NUXT_ENV_FIREBASE_API_KEY
      });
      firebase.auth().languageCode = "ru";
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible"
        }
      );
    }
  },
  methods: {
    async sendSmsCode() {
      if (!this.phoneAuth.number) {
        return;
      }
      this.confirmationResult = null;
      try {
        this.confirmationResult = await firebase
          .auth()
          .signInWithPhoneNumber(this.phoneAuth.number, this.recaptchaVerifier);
        this.$emit("expanded");
      } catch (e) {
        this.$sentry.captureException(e);
        this.errors.number = e.code;
      }
    },
    async signIn() {
      if (!this.phoneAuth.code) {
        return;
      }
      try {
        const result = await this.confirmationResult.confirm(
          this.phoneAuth.code
        );
        const idToken = await result.user.getIdToken();
        await this.$store.dispatch("authentication/phoneAuthenticate", idToken);
      } catch (e) {
        this.$sentry.captureException(e);
        this.errors.code = e.code;
      }
    }
  },
  computed: {
    phoneError() {
      return get(errorMessages, [this.errors.number], this.errors.number);
    },
    codeError() {
      return this.$t(get(errorMessages, [this.errors.code], this.errors.code));
    }
  }
};
