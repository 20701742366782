//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from "vuex-pathify";
import PhoneAuthForm from "./PhoneAuthForm";
import PhoneAuthFormPoints from "./PhoneAuthFormPoints";
import LoginSocialButtons from "./LoginSocialButtons";

export default {
  components: { LoginSocialButtons, PhoneAuthForm, PhoneAuthFormPoints },
  data() {
    return {
      showPhoneAuthForm: false,
      expanded: false,
    };
  },
  methods: {
    loginFormClose() {
      this.$router.push(this.localePath({ name: "index" }));
    },
  },
  computed: {
    visualImpairedModeSettings: get("visualImpairedModeSettings"),
    viModeEnabled: get("visualImpairedModeSettings/enabled"),
  },
};
