import { render, staticRenderFns } from "./MainHeader.vue?vue&type=template&id=113fd3e7&"
import script from "./MainHeader.vue?vue&type=script&lang=js&"
export * from "./MainHeader.vue?vue&type=script&lang=js&"
import style0 from "./MainHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LangSelect: require('/frontend/src/components/LangSelect.vue').default,MobileMenu: require('/frontend/src/components/MobileMenu.vue').default})
